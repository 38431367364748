/* eslint-disable */
export const messagePresentationMixin = {
  methods: {
    adjustMessage(msg) {
      let value = msg

      if (value.includes('http')) {
        let words = value.split(' ')
        let new_words = words.map(wd => {
          return wd.includes('http') ? this.buildLink(wd) : wd
        })
        value = new_words.join(' ')
      }

      //Convert all carriage return/line feed combos to solely line feeds
      value = value.replaceAll('\r\n', '\n')

      // Reduce multi-line line feeds to maximum of 2 line breaks to prevent excessive white space.
      while (value.indexOf('\n\n\n') > -1) {
        value = value.replaceAll('\n\n\n', '\n\n')
      }

      // Remove all leading line feeds.
      while (value.indexOf('\n') === 0) {
        value = value.substr(1)
      }

      // Convert line feeds into line break tags for HTML rendering.
      value = value.replaceAll('\n', '<br />')

      return value
    },
    buildLink(ws) {
      let pre = ''
      let suf = ''
      // separate out any characters at either end of the 'http' link
      if (ws.substr(0, 3) !== 'http') {
        let idx = ws.indexOf('http')
        pre = ws.substring(0, idx)
        ws = ws.substr(idx)
      }
      let lf = ws.indexOf('\n')
      if (lf > -1) {
        suf = ws.substr(lf)
        ws = ws.substring(0, lf)
      }

      // if a link is entered as http://webpage.com~resource, it will be converted to
      // <a href="http://webpage.com" target="_blank">resource</a>
      let wdp = ws.split('~')
      if (!wdp[1]) {
        wdp[1] = wdp[0]
      }
      return `${pre}<a href="${wdp[0]}" target="_blank">${wdp[1].replace(/_/g, ' ')}</a>${suf}`
    }
  }
}

export default messagePresentationMixin
