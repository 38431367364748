<template>
  <div v-if="!!obj">
    <div class="ann-card" :class="obj.icon ? obj.icon : determineClass">
      <header>
        <div class="d-flex">
          <div v-if="obj.cat && (obj.cat == 'camp' || obj.icon == 'camp')" class="icon">
            <iCamp />
          </div>
          <div v-if="obj.cat && (obj.cat == 'state' || obj.icon == 'state')" class="icon">
            <iState />
          </div>
          <div v-if="obj.cat && (obj.cat == 'international' || obj.icon == 'international')" class="icon">
            <iInternational />
          </div>
          <div
            v-if="
              obj.cat &&
              obj.cat != 'camp' &&
              obj.icon != 'camp' &&
              obj.cat != 'state' &&
              obj.icon != 'state' &&
              obj.cat != 'international' &&
              obj.icon != 'international'
            "
            class="icon"
          >
            <iOther />
          </div>
          <div class="meta">
            <slot name="header"></slot>
            <h5 class="cat" v-if="obj.state || obj.cat || obj.testimony_type !== `Other`">
              {{ setLabel }}
            </h5>
            <span class="ann-date">{{ objDate }}</span>
            <span class="pap-attach" v-if="!!obj.attachments && obj.attachments.length > 0">
              <iAttachment />
            </span>
          </div>
        </div>
      </header>
      <main>
        <div class="entry">
          <h3 class="title" :title="obj.msg_title">{{ obj.ttl || obj.title }}</h3>
          <div class="entry-content" :class="[obj.ttl ? 'shorter' : '']">
            <div class="content-text" v-if="obj.msg_message" v-html="previewRendered"></div>
            <div v-if="obj.desc" v-html="obj.desc"></div>
          </div>
        </div>
        <b-button @click="handleViewMoreClick(obj)" variant="primary">{{ i18n['ann-card'].tcReadMore }}</b-button>

        <div v-if="obj.art_key" class="entry tags">
          <b-row>
            <b-col sm="4" class="font-style-3 tags">
              <h3 class="title text-uppercase">{{ i18n['ann-card'].tcTags }}:</h3>
            </b-col>
            <b-col sm="8" class="font-style-3">
              <div class="entry-content shorter">
                <div v-if="obj.city || obj.state || obj.other || obj.topic">
                  {{ obj.city || '' }} {{ obj.state || '' }} {{ obj.other || '' }} {{ obj.topic || '' }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </main>
      <footer>
        <h3 v-if="obj.country" class="title">{{ obj.country }}</h3>
      </footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import iAttachment from '@/assets/svgs/ann-paperclip.vue'
import iCamp from '@/assets/svgs/tent-icon.vue'
import iInternational from '@/assets/svgs/international-icon.vue'
import iOther from '@/assets/svgs/ann-other-icon.vue'
import iState from '@/assets/svgs/flag-state-icon.vue'
import { messagePresentationMixin } from '@/mixins/messagePresentationMixin'

export default {
  mixins: [messagePresentationMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'ann-card': {
            tcCamp: 'camp',
            tcCommittee: 'committee',
            tcInternational: 'international',
            tcReadMore: 'Read More',
            tcState: 'state',
            tcTags: 'Tags',
          },
        }
      },
    },
    obj: Object,
  },
  components: {
    iAttachment: iAttachment,
    iCamp: iCamp,
    iInternational: iInternational,
    iOther: iOther,
    iState: iState,
  },
  data() {
    return {}
  },
  methods: {
    charLimit(text, limit) {
      if (text && typeof text === 'string') {
        return text.length > limit ? text.substring(0, limit - 3) + '...' : text
      }
    },
    evaluateCommitteeLabel: function () {
      let label = this.i18n['ann-card'].tcCommittee
      if (!!this.obj.filter) {
        // labelLookup needs to be in this format 'tcHeadquarters' to lookup the translation from SiteCore
        let labelLookup = this.obj.filter[0].trim().toLowerCase().split(' ')
        labelLookup = 'tc' + (labelLookup.map(label => label.charAt(0).toUpperCase() + label.slice(1)).join(''))
        label = this.i18n['ann-card'].hasOwnProperty(labelLookup) ?
          this.i18n['ann-card'][labelLookup] :
          this.obj.filter[0]
      }
      return label ? this.charLimit(label, 32) : this.i18n['ann-card'].tcCommittee
    },
    async handleViewMoreClick(evt) {
      this.$emit('view_more', evt)
    },
  },
  computed: {
    ...mapGetters({
      officerToolbarActive: 'user/officerToolbarActive',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
    }),
    determineClass() {
      let cats = ['camp', 'state', 'international', 'committee']
      if (cats.includes(this.obj.cat)) {
        return this.obj.cat || ''
      } else {
        return 'other' || ''
      }
    },
    objDate() {
      if (!this.obj) return ''
      let displayDate = this.obj.fromdate ? this.obj.fromdate : this.obj.testimony_date
      const newDate = new Date(displayDate)
      const formattedDateOptions = { year: 'numeric', month: 'long', day: 'numeric' }
      const formattedDate = newDate.toLocaleString(this.prefCulture, formattedDateOptions)
      return formattedDate ? formattedDate : displayDate
    },
    previewRendered() {
      return this.adjustMessage(this.obj.preview)
    },
    setLabel() {
      switch (this.objCat.toLowerCase()) {
        case 'state':
          return this.obj.state
        case 'camp':
          return this.officerToolbarActive ? this.officerToolbarSelected.camp_name : this.userLogin.campname
        case 'committee':
          return this.evaluateCommitteeLabel()
        case 'international':
          return this.i18n['ann-card'].tcInternational
        default:
          return this.objCat
      }
    },
    objCat() {
      return this.obj && this.obj.cat ? this.obj.cat : ''
    },
  },
}
</script>

<style scoped>
.content-text {
  max-height: 290px;
  overflow: hidden;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
.ann-card {
  position: relative;
  background-color: #fff;
  box-shadow: 0 6px 12px 6px rgba(157, 157, 157, 0.5);
  @include breakpoint(sm) {
    box-shadow: 0 17px 45px 11px rgba(227, 227, 227, 0.53);
  }
  header {
    padding: 20px 10px;
    display: flex;
    .icon svg {
      margin-right: 5px;
    }
    .pap-attach {
      margin-left: 10px;
    }
    .meta {
      color: #fff;
      font-size: 14px;
      h5.cat {
        font-weight: 900;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 2px;
      }
    }
  }
  main {
    height: calc(100% - 87px);
    padding: 30px 15px;
    text-align: center;
    .entry {
      height: calc(100% - 46px);
      text-align: left;
    }
    h3 {
      margin-bottom: 10px;
      color: #000;
      font-family: $DIN;
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      // text-transform: uppercase;
    }
    .entry-content {
      height: 300px;
      padding: 0 10px;
      .shorter {
        height: 200px;
      }
      p {
        margin-bottom: 23px;
        color: #4c4c4c;
        font-family: $lora;
        font-size: 16px;
        letter-spacing: 0.64px;
        line-height: 28px;
      }
    }
    a.btn {
      margin: 0 auto;
    }
  }
  footer {
    height: 8px;
    width: 100%;
  }
  &.camp {
    header,
    footer,
    .btn {
      background-color: #003946;
    }
    .btn:hover {
      background-color: #fbc301;
    }
  }
  &.state {
    header,
    footer,
    .btn {
      background-color: #e85f00;
    }
    .btn:hover {
      background-color: #fbc301;
    }
  }
  &.international {
    header,
    footer,
    .btn {
      background-color: #019c9b;
    }
    .btn:hover {
      background-color: #fbc301;
    }
  }
  &.committee {
    header,
    footer,
    .btn {
      background-color: #7e7b66;
    }
    .btn:hover {
      background-color: #fbc301;
    }
  }
  &.other {
    header,
    footer,
    .btn {
      background-color: #7e7b66;
    }
    .btn:hover {
      background-color: #fbc301;
    }
  }
}
</style>
